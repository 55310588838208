/* eslint-disable no-param-reassign */
import { configureStore } from "@reduxjs/toolkit";
import courseDataSlice from "./courseData-slice";
import courseSyllabiSlice from "./courseSyllabi-slice";
import selectedCourseSlice from "./selectedCourse-slice";

// Create the store, adding the search slice to it
const store = configureStore({
  reducer: {
    courseData: courseDataSlice.reducer,
    courseSyllabi: courseSyllabiSlice.reducer,
    selectedCourse: selectedCourseSlice.reducer,
  },
});

export default store;
