/** 
  All of the routes for the LiahStudy React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Navbar.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `name` key is used for the name of the route on the Navbar.
  2. The `icon` key is used for the icon of the route on the Navbar.
  3. The `collapse` key is used for making a collapsible item on the Navbar that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  4. The `route` key is used to store the route location which is used for the react router.
  5. The `href` key is used to store the external links location.
  6. The `component` key is used to store the component of its route.
  7. The `dropdown` key is used to define that the item should open a dropdown for its collapse items .
  8. The `description` key is used to define the description of
          a route under its name.
  9. The `columns` key is used to define that how the content should look inside the dropdown menu as columns,
          you can set the columns amount based on this key.
  10. The `rowsPerColumn` key is used to define that how many rows should be in a column.
*/

// react redux stuff
// import store from "app/store";

// @mui material components
// import Icon from "@mui/material/Icon";

//  react-icons
// import { FaSignInAlt } from "react-icons/fa";
// import { MdArticle } from "react-icons/md";

// Pages
// import SignIn from "layouts/pages/authentication/sign-in";
// import Course from "layouts/pages/Programs/course";
// import Syllabus from "layouts/pages/Programs/Syllabus";
import TrainingProgram from "pages/Programs/TrainingProgram";
import SearchResults from "pages/SearchResults/SearchResults";
import Course from "layouts/pages/Programs/course";
import Lesson from "layouts/pages/Programs/Lesson";

import { ROUTE, TRAINING_PROGRAM_ROUTE } from ".";

const routes = [
  {
    path: ROUTE.TRAINING_PROGRAM,
    name: "Training program",
    element: <TrainingProgram />,
    exact: true,
    selected: false,
  },
  {
    path: ROUTE.SEARCH,
    name: "Search",
    element: <SearchResults />,
    exact: true,
    selected: false,
  },
  {
    path: TRAINING_PROGRAM_ROUTE.COURSE,
    name: "Course",
    element: <Course />,
    exact: true,
    selected: false,
  },
  // {
  //   path: TRAINING_PROGRAM_ROUTE.LESSON,
  //   name: "Lesson",
  //   element: <Lesson />,
  //   exact: true,
  //   selected: false,
  // },
  // {
  //   name: "account",
  //   collapse: [
  //     {
  //       name: "sign in",
  //       icon: <FaSignInAlt />,
  //       route: "/pages/authentication/sign-in",
  //       component: <SignIn />,
  //     },
  //   ],
  // },
];

export const inCourseRoutes = [
  {
    path: TRAINING_PROGRAM_ROUTE.LESSON,
    name: "Lesson",
    element: <Lesson />,
    exact: true,
    selected: false,
  },
];

export default routes;
