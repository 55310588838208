/**
 * Generates an array of route objects based on the provided basic data.
 * Each route object represents a syllabus item of a course.
 * The route object includes properties like name, image, pro, count, and route.
 * Additionally, each route object includes syllabi names, contents, and routes.
 * The route property is used to create a unique route URL for React Router navigation.
 * @param {Array} theBasicData - The basic data containing course information and syllabus items.
 * @param {string} prefix - The prefix for generating the route URL (default: '/learn').
 * @returns {Array} - An array of route objects for each syllabus item.
 */
export const generateRoutesFromData = (theBasicData, prefix = "/learn") =>
  theBasicData.flatMap((course) =>
    course.items.flatMap((syllabusItem) =>
      syllabusItem.syllabi.map((syllabus) => ({
        title: course.title,
        description: course.description,
        items: {
          name: syllabusItem.name,
          image: syllabusItem.image,
          pro: syllabusItem.pro,
          count: syllabusItem.count,
          route: `${prefix}/${course.title.toLowerCase().replace(/\s/g, "-")}/${syllabusItem.name
            .toLowerCase()
            .replace(/\s/g, "-")}/*`,
          syllabi: {
            name: syllabus.name,
            content: syllabus.content,
            route: `${prefix}/${course.title.toLowerCase().replace(/\s/g, "-")}/${syllabusItem.name
              .toLowerCase()
              .replace(/\s/g, "-")}/${syllabus.name.toLowerCase().replace(/\s/g, "-")}`,
          },
        },
      }))
    )
  );

export const sameStruct = (theBasicData, prefix = "/learn") =>
  theBasicData.map((obj) => {
    const hyphen = "-";
    return {
      title: obj.title,
      description: obj.description,
      items: obj.items.map((item) => ({
        name: item.name,
        image: item.image,
        pro: item.pro,
        count: item.count,
        route: `${prefix}/${obj.title.toLowerCase().replace(/\s/g, hyphen)}/${item.name
          .toLowerCase()
          .replace(/\s/g, hyphen)}`,
      })),
    };
  });

export const formatItemNumber = (itemNumber) => {
  const num = Number(itemNumber);
  if (num < 10) {
    return `00${num}`;
  }
  if (num < 100) {
    return `0${num}`;
  }
  return `${num}`;
};

/**
 *
 * @returns a six digit character id
 */

// export function makeId() {
//   const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890abcdefghijklmnopqrstuvwxyz";
//   let text = "";
//   for (let i = 0; i < 5; i += 1) {
//     text += possible.charAt(Math.floor(Math.random().possible.length));
//   }
//   return text;
// }

export function binarySearch(arr, target) {
  let left = 0;
  let right = arr.length - 1;

  while (left <= right) {
    const mid = Math.floor((left + right) / 2);
    const midCourse = arr[mid].name;

    if (midCourse === target) {
      return arr[mid];
    }
    if (midCourse < target) {
      left = mid + 1;
    } else {
      right = mid - 1;
    }
  }

  return null; // Course not found
}
