/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-typos */
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import Container from "@mui/material/Container";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import breakpoints from "assets/theme/base/breakpoints";
import { formatItemNumber } from "app/functions";
import "./styles.css";
import { Drawer, Icon } from "@mui/material";

const List = ({ item, itemNumber }) => (
  // const { pathname } = window.location;
  <Link to={`lesson/${item}`}>
    <MKBox
      display="flex"
      textAlign="left"
      alignItems="center"
      width={{ xs: "100%", lg: "23.2vw" }}
      height="7vh"
      fontSize="14px"
      mx={0}
      padding="1.5vh 2vh 3vh 2vh"
      sx={{
        "&:hover": {
          transform: "rotateX(7deg)",
          cursor: "pointer",
          background: "gainsboro",
        },
      }}
    >
      <MKTypography variant="body2" fontSize="16px" fontFamily="courier">
        {itemNumber}
      </MKTypography>
      <MKBox width="1rem" />
      <MKTypography variant={{ xs: "body3", lg: "body2" }}>{item}</MKTypography>
      {/* <MKBox>{lessonSelected.toString()}</MKBox> */}
    </MKBox>
  </Link>
);
function DefaultSidebar({ foundCourseData, transparent, light, sticky, relative, center }) {
  console.log(foundCourseData);
  const { urlModuleName } = useParams();
  const bgImage = "https://web.dev/images/courses/css/background.svg";
  const module = foundCourseData.modules.find((moduleItem) => moduleItem.name === urlModuleName);
  const moduleName = module.name;

  const [mobileSidebar, setMobileSidebar] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const openMobileSidebar = () => setMobileSidebar(!mobileSidebar);
  useEffect(() => {
    // A function that sets the display state for the DefaultSidebarMobile.
    function displayMobileSidebar() {
      if (window.innerWidth < breakpoints.values.lg) {
        setMobileView(true);
        setMobileSidebar(false);
      } else {
        setMobileView(false);
        setMobileSidebar(false);
      }
    }
    /** 
     The event listener that's calling the displayMobileSidebar function when 
     resizing the window.
    */
    window.addEventListener("resize", displayMobileSidebar);

    // Call the displayMobileSidebar function to set the state with the initial value.
    displayMobileSidebar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", displayMobileSidebar);
  }, []);
  const handleDrawerToggle = () => {
    setMobileSidebar(!mobileSidebar);
  };
  return (
    <Container
      padding={0}
      sx={
        sticky
          ? { position: "sticky", top: 0, left: "0", zIndex: 10 }
          : { position: "absolute", left: 0 }
      }
    >
      {mobileView ? (
        <>
          <MKBox
            display={{ xs: "inline-block", lg: "none" }}
            lineHeight={0}
            position="fixed"
            zIndex="2"
            py={1.5}
            pl={1.5}
            color={transparent ? "white" : "inherit"}
            sx={{ cursor: "pointer" }}
            onClick={openMobileSidebar}
          >
            <Icon fontSize="default">{mobileSidebar ? "close" : "menu"}</Icon>
          </MKBox>
          <Drawer
            variant="temporary"
            anchor="left"
            open={mobileSidebar}
            onClose={handleDrawerToggle}
          >
            {/* Sidebar content */}
            <MKBox
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              alignItems="center"
            >
              <MKBox display="sticky" textAlign="center" top={0} zIndex={4}>
                <MKBox
                  height="20vh"
                  width="100%"
                  lineHeight={1}
                  py={transparent ? 1.5 : 0.75}
                  pl={relative || transparent ? 0 : { xs: 0, lg: 1 }}
                  alignItems="center"
                  sx={{
                    backgroundImage: `url(${bgImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "top",
                    display: "grid",
                    fontSize: "1.2rem",
                    placeItems: "center",
                  }}
                >
                  {/* <Logo /> */}
                </MKBox>
                <MKTypography
                  component={Link}
                  to="/"
                  textAlign="center"
                  variant="button"
                  padding="1rem"
                  fontWeight="bold"
                  fontSize="20px"
                  bottom={5}
                  color={light ? "white" : "dark"}
                >
                  {moduleName}
                </MKTypography>
              </MKBox>
              <MKBox
                color="inherit"
                display={{ xs: "flex 1", lg: "flex" }}
                height="fit-content"
                overflowY="scroll"
                ml="0"
                width="100%"
                mt="20px"
                flexDirection="column"
                textAlign="left"
              >
                {module.lessons.map((lesson, index) => (
                  <div
                    onClick={() => handleDrawerToggle()}
                    onKeyDown={() => handleDrawerToggle()}
                    className={`navigation-item ${
                      urlModuleName === lesson.name.trim() ? "active" : ""
                    }`}
                    backgroundColor="blue"
                    key={lesson.name}
                  >
                    <List
                      key={lesson.name}
                      item={lesson.name}
                      itemNumber={formatItemNumber(index)}
                    />
                  </div>
                ))}
              </MKBox>
            </MKBox>
          </Drawer>
        </>
      ) : (
        <MKBox
          className="sidebar"
          py={0}
          px={{ xs: 4, sm: transparent ? 2 : 3, lg: transparent ? 0 : 0 }}
          my={relative ? 1 : 0}
          mx={relative ? 1 : 0}
          borderRight="1px solid gainsboro"
          width={relative ? "fit-content" : "fit-content)"}
          shadow={transparent ? "none" : "md"}
          color={light ? "white" : "dark"}
          position={relative ? "relative" : "absolute"}
          left={0}
          zIndex={3}
          sx={({ palette: { transparent: transparentColor, white }, functions: { rgba } }) => ({
            backgroundColor: transparent ? transparentColor.main : rgba(white.main, 0.8),
            backdropFilter: transparent ? "none" : `saturate(200%) blur(30px)`,
          })}
        >
          <MKBox
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="center"
          >
            <MKBox display="sticky" textAlign="center" top={0} zIndex={4}>
              <MKBox
                height="20vh"
                width="100%"
                lineHeight={1}
                py={transparent ? 1.5 : 0.75}
                pl={relative || transparent ? 0 : { xs: 0, lg: 1 }}
                alignItems="center"
                sx={{
                  backgroundImage: `url(${bgImage})`,
                  backgroundSize: "cover",
                  backgroundPosition: "top",
                  display: "grid",
                  fontSize: "1.2rem",
                  placeItems: "center",
                }}
              >
                {/* <Logo /> */}
              </MKBox>
              <MKTypography
                component={Link}
                to="/"
                textAlign="center"
                variant="button"
                fontWeight="bold"
                fontSize="20px"
                bottom={5}
                color={light ? "white" : "dark"}
              >
                {moduleName}
              </MKTypography>
              {/* <MKBox>
              <DefaultSearchbar />
            </MKBox> */}
            </MKBox>
            <MKBox
              backgroundColor="red"
              color="inherit"
              display={{ xs: "none", lg: "flex" }}
              height="67vh"
              overflowY="scroll"
              ml="0"
              mt="20px"
              flexDirection="column"
              textAlign="left"
              mr={center ? "auto" : 0}
            >
              {module.lessons.map((lesson, index) => (
                <div
                  className={`navigation-item ${
                    urlModuleName === lesson.name.trim() ? "active" : ""
                  }`}
                  backgroundColor="blue"
                  key={lesson.name}
                >
                  <List
                    key={lesson.name}
                    item={lesson.name}
                    itemNumber={formatItemNumber(index)}
                    // onClick={() => handleLessonClick(lesson.name)}
                  />
                </div>
              ))}
            </MKBox>
          </MKBox>
        </MKBox>
      )}
    </Container>
  );
}

// Typechecking props for the List
List.propTypes = {
  item: PropTypes.string.isRequired,
  itemNumber: PropTypes.string.isRequired,
};

// Setting default values for the props of DefaultNavbar
DefaultSidebar.defaultProps = {
  transparent: false,
  light: false,
  sticky: false,
  relative: false,
  center: false,
};

// Typechecking props for the DefaultSidebar
DefaultSidebar.propTypes = {
  foundCourseData: PropTypes.shape({
    name: PropTypes.string.isRequired,
    count: PropTypes.number.isRequired,
    modules: PropTypes.arrayOf(
      PropTypes.shape({
        lessons: PropTypes.array.isRequired,
      })
    ),

    pro: PropTypes.bool,
  }).isRequired,
  transparent: PropTypes.bool,
  light: PropTypes.bool,
  sticky: PropTypes.bool,
  relative: PropTypes.bool,
  center: PropTypes.bool,
};

export default DefaultSidebar;
