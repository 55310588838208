/*
=========================================================
* LiahStudy - v2.0.0
=========================================================

* Product Page: https://www.tekstedia.com/product/material-kit-react
* Copyright 2023 TeksTedia (https://www.tekstedia.com)

Coded by www.tekstedia.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// LiahStudy pages
import Presentation from "pages/Presentation";

export default function PresentationPage() {
  return <Presentation />;
}
