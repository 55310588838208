import { Link, useParams, Outlet } from "react-router-dom";
import { selectedCourseActions } from "app/selectedCourse-slice";
import PropTypes from "prop-types";

import { useDispatch } from "react-redux"; // react redux stuff
import store from "app/store";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import routes from "routes/routes";
import CheckCircleSharpIcon from "@mui/icons-material/CheckCircleSharp";
import "./styles.css";
import { LinearProgress, Stack, linearProgressClasses, styled } from "@mui/material";
import MKButton from "components/MKButton";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import Box from "@mui/material/Box";
import CircularProgress, { circularProgressClasses } from "@mui/material/CircularProgress";

const { default: MKTypography } = require("components/MKTypography");

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

// Inspired by the former Facebook spinners.
export function FacebookCircularProgress(props) {
  return (
    <Box sx={{ position: "relative" }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) => theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
        }}
        size={40}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: (theme) => (theme.palette.mode === "light" ? "#1a90ff" : "#308fe8"),
          animationDuration: "550ms",
          position: "absolute",
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
        }}
        size={40}
        thickness={4}
        {...props}
      />
    </Box>
  );
}

function Indicator() {
  return (
    <Stack justifyContent="space-between" alignItems="center" gap=".5pc">
      <div className="circle" />
      <div className="line" />
      <div className="circle" />
    </Stack>
  );
}
function Item(props) {
  const { name, description, routifiedCourseName } = props;
  return (
    <Box id="Item" component={Link} to={`/course/${routifiedCourseName}/module/${name}`}>
      <Stack justifyContent="space-between" alignItems="center" direction="row" spacing="2">
        <Stack justifyContent="space-between" alignItems="left">
          <MKTypography>{name}</MKTypography>
          <MKTypography variant="body2">{description}</MKTypography>
        </Stack>
        <CheckCircleSharpIcon color="green" />
      </Stack>
    </Box>
  );
}
const SingularCourse = () => {
  const { name, urlModuleName } = useParams();
  const dispatch = useDispatch();
  const { data } = store.getState().courseData;

  const hyphenRemovedName = name.toLowerCase().replace(/-/g, " ");
  let foundCourse = null;
  data.forEach((trainingProgram) => {
    trainingProgram.courses.forEach((course) => {
      if (course.name.toLowerCase() === hyphenRemovedName) {
        foundCourse = course;
        dispatch(selectedCourseActions.setCourse(foundCourse));
        // Use 'return' to exit the inner loop
      }
    });
  });
  const modulesArray = foundCourse.modules;
  const routifiedCourseName = foundCourse.name.toLowerCase().replace(/\s/g, "-");
  if (urlModuleName) {
    return (
      <>
        <Outlet />
      </>
    );
  }
  console.log(modulesArray);
  return (
    <>
      <DefaultNavbar routes={routes} sticky />
      <aside />
      <main>
        <div>
          <MKTypography className="courseName" variant="h3" color="black">
            {foundCourse.name}
          </MKTypography>
          <br />
          <BorderLinearProgress variant="determinate" color="info" value={50} />
          <br />
        </div>
        <section className="welcome-section">
          <MKTypography variant="body2">Pick up where you left off</MKTypography>
          <div className="topic">
            <MKTypography>What is Javascript?</MKTypography>
            <MKTypography
              component={MKButton}
              color="info"
              endIcon={<ArrowRightAltIcon />}
              fontWeight={600}
              variant="body2"
            >
              Resume
            </MKTypography>
          </div>
        </section>

        <section className="modules">
          <MKTypography>Modules</MKTypography>
          {modulesArray.map((module) => (
            <Stack key={module.description} marginTop="1rem" direction="row" spacing="2pc">
              <div>
                <Indicator />
              </div>
              <Item
                name={module.name}
                description={module.description}
                routifiedCourseName={routifiedCourseName}
              />
            </Stack>
          ))}
        </section>
      </main>
    </>
  );
};

Item.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  routifiedCourseName: PropTypes.string.isRequired,
};
export default SingularCourse;
