import MKBox from "components/MKBox";
// LiahStudy examples
// import DefaultSidebar from "examples/Sidebars/DefaultSidebar";
// import SimpleFooter from "examples/Footers/SimpleFooter";

// Routes
// import footerRoutes from "footer.routes";

const SearchResults = () => <MKBox backgroundColor="white">Hello . . . World</MKBox>;

export default SearchResults;
