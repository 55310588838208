import Page from "assets/images/page.png";

const overviewNotes = `[Overview] The "Fundamentals of HTML, CSS, and JS" course is designed to provide web developers, from novice to expert level, with a solid overview of HTML. Whether you're just starting out or have been building websites for years, this course offers valuable knowledge and fills in any gaps you may have in your understanding of HTML.
Throughout the course, you'll embark on a journey to build the structure for MachineLearningWorkshop.com. It's important to note that no machines were harmed during the creation of this series.
Please keep in mind that this course is not a complete reference. Each section introduces a specific topic with concise explanations and examples, encouraging further exploration. You'll find links to topic references such as MDN and WHATWG specifications, as well as other web.dev articles, providing additional resources for in-depth learning. While this course does not focus exclusively on accessibility, each section includes accessibility best practices and highlights specific issues, with links to delve deeper into the topic. Additionally, each section concludes with a short assessment to help solidify your understanding.
Here's what you'll learn:
Overview of HTML: This section offers a brief introduction to the key concepts in HTML.
Document structure: Learn how to structure your HTML documents with a strong foundation.
Metadata: Understand how to utilize meta tags to provide information about your documents.
Semantic HTML: Discover the importance of using the correct HTML elements to describe your document content accurately.
Headings and sections: Learn the proper usage of sectioning elements to give meaning to your content.
Attributes: Explore different global attributes and attributes specific to particular HTML elements.
Text basics: Master the art of formatting text using HTML.
Links: Gain comprehensive knowledge about creating and working with links.
Lists: Understand various ways to group and organize your content using lists.
Navigation: Learn how to implement navigation, a crucial element for any website or application, using HTML.
Tables: Acquire the skills to effectively mark up tabular data using HTML tables.
Forms: Get an overview of forms in HTML and their components.
Images: Discover how to work with images in HTML.
Audio and Video: Explore the incorporation and manipulation of audio and video content using HTML.
Template, slot, and shadow: Understand the concepts of template, slot, and shadow in HTML.
HTML APIs: Learn how HTML information can be exposed and manipulated using JavaScript.
Focus: Discover techniques for managing the focus order in your HTML documents.
Other inline text elements: Get acquainted with a range of elements used for marking up text.
Details and summary: Understand the functionality of the details and summary elements and their appropriate usage.
Dialog: Explore the dialog element, a useful tool for representing different types of dialogues in HTML.
Conclusion and next steps: Wrap up the course with additional resources for further learning.
So, if you're ready to learn HTML and enhance your web development skills, let's get started on this exciting journey!
                `;
export default [
  {
    trainingPrograms: "Web Design",
    description:
      "Unlock your creativity and build stunning websites with Tekstedia's expert-led Training",
    courses: [
      {
        image: Page,
        modules: [
          {
            name: "What is HTML",
            description: "Learn the history of HTML, the web and how the internet works",
            lessons: [
              {
                name: "Overview",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=DHGhFJZLKMs"],
                  notes: overviewNotes,
                },
              },
              {
                name: "Javascript Demo",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],

                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "CIOc ds",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "Bgeini ",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "Immediately Evoked",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "Xexrse Zul",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "JIocdsc ",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "cdoinsoi sd",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "Marvel of Z-index",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "uiewe d",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
            ],
          },
        ],

        name: "Fundamentals of HTML CSS and JS",
        count: 10,
      },
      {
        image: Page,
        modules: [
          {
            name: "Basics of UX design",
            description: "Learn the history of HTML, the web and how the internet works",
            lessons: [
              {
                name: "Overview",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=DHGhFJZLKMs"],
                  notes: overviewNotes,
                },
              },
              {
                name: "Javascript Demo",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],

                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "CIOc ds",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "Bgeini ",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "Immediately Evoked",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "Xexrse Zul",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "JIocdsc ",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "cdoinsoi sd",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "Marvel of Z-index",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "uiewe d",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
            ],
          },
        ],

        name: "UX Design Principles",
        count: 14,
      },
      {
        image: Page,
        modules: [
          {
            name: "General Knowledge about responsive design",
            description: "Learn the history of HTML, the web and how the internet works",
            lessons: [
              {
                name: "Overview",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=DHGhFJZLKMs"],
                  notes: overviewNotes,
                },
              },
              {
                name: "Javascript Demo",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],

                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "CIOc ds",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "Bgeini ",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "Immediately Evoked",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "Xexrse Zul",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "JIocdsc ",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "cdoinsoi sd",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "Marvel of Z-index",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "uiewe d",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
            ],
          },
        ],

        name: "Responsive Design Techniques",
        count: 8,
        pro: true,
      },
      {
        image: Page,
        modules: [
          {
            name: "What is Project management",
            description: "Learn the history of HTML, the web and how the internet works",
            lessons: [
              {
                name: "Overview",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=DHGhFJZLKMs"],
                  notes: overviewNotes,
                },
              },
              {
                name: "Javascript Demo",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],

                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "CIOc ds",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "Bgeini ",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "Immediately Evoked",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "Xexrse Zul",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "JIocdsc ",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "cdoinsoi sd",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "Marvel of Z-index",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "uiewe d",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
            ],
          },
        ],

        name: "Web Design Project Management and workflow",
        count: 1,
        pro: true,
      },
      {
        image: Page,
        modules: [
          {
            name: "CMS and Webhosting",
            description: "Learn the history of HTML, the web and how the internet works",
            lessons: [
              {
                name: "Overview",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=DHGhFJZLKMs"],
                  notes: overviewNotes,
                },
              },
              {
                name: "Javascript Demo",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],

                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "CIOc ds",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "Bgeini ",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "Immediately Evoked",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "Xexrse Zul",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "JIocdsc ",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "cdoinsoi sd",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "Marvel of Z-index",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "uiewe d",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
            ],
          },
        ],

        name: "CMS and Web Hosting Basics",
        count: 11,
        pro: true,
      },
      {
        image: Page,
        modules: [
          {
            name: "What is accessibility for the web?",
            description: "Learn the history of HTML, the web and how the internet works",
            lessons: [
              {
                name: "Overview",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=DHGhFJZLKMs"],
                  notes: overviewNotes,
                },
              },
              {
                name: "Javascript Demo",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],

                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "CIOc ds",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "Bgeini ",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "Immediately Evoked",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "Xexrse Zul",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "JIocdsc ",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "cdoinsoi sd",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "Marvel of Z-index",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "uiewe d",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
            ],
          },
        ],

        name: "Accessibility and inclusive Design for the Web",
        count: 11,
        pro: true,
      },
      {
        image: Page,
        modules: [
          {
            name: "Advancing tech",
            description: "Learn the history of HTML, the web and how the internet works",
            lessons: [
              {
                name: "Overview",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=DHGhFJZLKMs"],
                  notes: overviewNotes,
                },
              },
              {
                name: "Javascript Demo",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],

                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "CIOc ds",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "Bgeini ",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "Immediately Evoked",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "Xexrse Zul",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "JIocdsc ",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "cdoinsoi sd",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "Marvel of Z-index",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "uiewe d",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
            ],
          },
        ],

        name: "Advanced webdesign Concepts and Emerging Tech",
        count: 6,
        pro: true,
      },
      {
        image: Page,
        modules: [
          {
            name: "Knowing the stats",
            description: "Learn the history of HTML, the web and how the internet works",
            lessons: [
              {
                name: "Overview",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=DHGhFJZLKMs"],
                  notes: overviewNotes,
                },
              },
              {
                name: "Javascript Demo",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],

                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "CIOc ds",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "Bgeini ",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "Immediately Evoked",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "Xexrse Zul",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "JIocdsc ",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "cdoinsoi sd",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "Marvel of Z-index",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "uiewe d",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
            ],
          },
        ],

        name: "Stats",
        count: 3,
        pro: true,
      },
      {
        image: Page,
        modules: [
          {
            name: "Designing Real world Apps",
            description: "Learn the history of HTML, the web and how the internet works",
            lessons: [
              {
                name: "Overview",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=DHGhFJZLKMs"],
                  notes: overviewNotes,
                },
              },
              {
                name: "Javascript Demo",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],

                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "CIOc ds",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "Bgeini ",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "Immediately Evoked",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "Xexrse Zul",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "JIocdsc ",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "cdoinsoi sd",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "Marvel of Z-index",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "uiewe d",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
            ],
          },
        ],

        name: "Applications",
        count: 6,
        pro: true,
      },
    ],
    action: {
      route: "/presentation",
      label: "Enroll",
    },
  },
  {
    trainingPrograms: "Industrial software designing and creation",
    description: "Robust tech",
    courses: [
      {
        image: Page,
        modules: [
          {
            name: "Who is the Industry",
            description: "Know about the industry's specifications",
            lessons: [
              {
                name: "Overview",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=DHGhFJZLKMs"],
                  notes: overviewNotes,
                },
              },
              {
                name: "Javascript Demo",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],

                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "CIOc ds",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "Bgeini ",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "Immediately Evoked",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "Xexrse Zul",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "JIocdsc ",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "cdoinsoi sd",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "Marvel of Z-index",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "uiewe d",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
            ],
          },
          {
            name: "Why is the Industry",
            description: "If you know you know",
            lessons: [
              {
                name: "Overview",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=DHGhFJZLKMs"],
                  notes: overviewNotes,
                },
              },
              {
                name: "Javascript Demo",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],

                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "CIOc ds",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "Bgeini ",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "Immediately Evoked",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "Xexrse Zul",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "JIocdsc ",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "cdoinsoi sd",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "Marvel of Z-index",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "uiewe d",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
            ],
          },
        ],

        name: "Fundamentals of HTML CSS and JS",
        count: 10,
      },
      {
        image: Page,
        modules: [
          {
            name: "Basics of UX design",
            description: "Learn the history of HTML, the web and how the internet works",
            lessons: [
              {
                name: "Overview",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=DHGhFJZLKMs"],
                  notes: overviewNotes,
                },
              },
              {
                name: "Javascript Demo",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],

                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "CIOc ds",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "Bgeini ",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "Immediately Evoked",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "Xexrse Zul",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "JIocdsc ",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "cdoinsoi sd",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "Marvel of Z-index",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "uiewe d",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
            ],
          },
        ],

        name: "UX Design Principles",
        count: 14,
      },
      {
        image: Page,
        modules: [
          {
            name: "General Knowledge about responsive design",
            description: "Learn the history of HTML, the web and how the internet works",
            lessons: [
              {
                name: "Overview",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=DHGhFJZLKMs"],
                  notes: overviewNotes,
                },
              },
              {
                name: "Javascript Demo",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],

                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "CIOc ds",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "Bgeini ",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "Immediately Evoked",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "Xexrse Zul",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "JIocdsc ",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "cdoinsoi sd",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "Marvel of Z-index",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "uiewe d",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
            ],
          },
        ],

        name: "Responsive Design Techniques",
        count: 8,
        pro: true,
      },
      {
        image: Page,
        modules: [
          {
            name: "What is Project management",
            description: "Learn the history of HTML, the web and how the internet works",
            lessons: [
              {
                name: "Overview",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=DHGhFJZLKMs"],
                  notes: overviewNotes,
                },
              },
              {
                name: "Javascript Demo",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],

                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "CIOc ds",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "Bgeini ",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "Immediately Evoked",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "Xexrse Zul",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "JIocdsc ",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "cdoinsoi sd",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "Marvel of Z-index",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "uiewe d",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
            ],
          },
        ],

        name: "Web Design Project Management and workflow",
        count: 1,
        pro: true,
      },
      {
        image: Page,
        modules: [
          {
            name: "CMS and Webhosting",
            description: "Learn the history of HTML, the web and how the internet works",
            lessons: [
              {
                name: "Overview",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=DHGhFJZLKMs"],
                  notes: overviewNotes,
                },
              },
              {
                name: "Javascript Demo",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],

                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "CIOc ds",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "Bgeini ",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "Immediately Evoked",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "Xexrse Zul",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "JIocdsc ",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "cdoinsoi sd",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "Marvel of Z-index",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "uiewe d",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
            ],
          },
        ],

        name: "CMS and Web Hosting Basics",
        count: 11,
        pro: true,
      },
      {
        image: Page,
        modules: [
          {
            name: "What is accessibility for the web?",
            description: "Learn the history of HTML, the web and how the internet works",
            lessons: [
              {
                name: "Overview",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=DHGhFJZLKMs"],
                  notes: overviewNotes,
                },
              },
              {
                name: "Javascript Demo",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],

                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "CIOc ds",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "Bgeini ",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "Immediately Evoked",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "Xexrse Zul",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "JIocdsc ",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "cdoinsoi sd",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "Marvel of Z-index",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "uiewe d",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
            ],
          },
        ],

        name: "Accessibility and inclusive Design for the Web",
        count: 11,
        pro: true,
      },
      {
        image: Page,
        modules: [
          {
            name: "Advancing tech",
            description: "Learn the history of HTML, the web and how the internet works",
            lessons: [
              {
                name: "Overview",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=DHGhFJZLKMs"],
                  notes: overviewNotes,
                },
              },
              {
                name: "Javascript Demo",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],

                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "CIOc ds",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "Bgeini ",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "Immediately Evoked",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "Xexrse Zul",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "JIocdsc ",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "cdoinsoi sd",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "Marvel of Z-index",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "uiewe d",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
            ],
          },
        ],

        name: "Advanced webdesign Concepts and Emerging Tech",
        count: 6,
        pro: true,
      },
      {
        image: Page,
        modules: [
          {
            name: "Knowing the stats",
            description: "Learn the history of HTML, the web and how the internet works",
            lessons: [
              {
                name: "Overview",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=DHGhFJZLKMs"],
                  notes: overviewNotes,
                },
              },
              {
                name: "Javascript Demo",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],

                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "CIOc ds",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "Bgeini ",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "Immediately Evoked",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "Xexrse Zul",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "JIocdsc ",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "cdoinsoi sd",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "Marvel of Z-index",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "uiewe d",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
            ],
          },
        ],

        name: "Stats",
        count: 3,
        pro: true,
      },
      {
        image: Page,
        modules: [
          {
            name: "Designing Real world Apps",
            description: "Learn the history of HTML, the web and how the internet works",
            lessons: [
              {
                name: "Overview",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=DHGhFJZLKMs"],
                  notes: overviewNotes,
                },
              },
              {
                name: "Javascript Demo",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],

                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "CIOc ds",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "Bgeini ",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "Immediately Evoked",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "Xexrse Zul",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "JIocdsc ",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "cdoinsoi sd",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "Marvel of Z-index",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
              {
                name: "uiewe d",
                content: {
                  quizzes: [
                    {
                      question: "What happened when you ran the code in the js console?",
                      answers: [
                        "The heading disappeared",
                        "The heading changed to red",
                        "All the text on the page turned to red",
                        "Nothing happened",
                      ],
                    },
                  ],
                  videos: ["https://www.youtube.com/watch?v=00AkMN9IAAY"],
                  overviewNotes,
                },
              },
            ],
          },
        ],

        name: "Applications",
        count: 6,
        pro: true,
      },
    ],
    action: {
      route: "/presentation",
      label: "Enroll",
    },
  },
];
