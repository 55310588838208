/*
=========================================================
* LiahStudy - v2.0.0
=========================================================

* Product Page: https://www.tekstedia.com/product/material-kit-react
* Copyright 2021 TeksTedia (https://www.tekstedia.com)

Coded by www.tekstedia.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// React-icons
import { MdTouchApp, MdContentCopy, MdFlipToFront, MdOutlineDevices } from "react-icons/md";
import { IoMdCash } from "react-icons/io";
// LiahStudy components
import MKBox from "components/MKBox";

// LiahStudy examples
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Images

function Information() {
  const bgFront =
    "https://images.unsplash.com/photo-1500259783852-0ca9ce8a64dc?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjV8fHBlcnNvbnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60";
  const bgBack =
    "https://images.unsplash.com/photo-1503249023995-51b0f3778ccf?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjZ8fHBlcnNvbnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60";
  return (
    <MKBox component="section" py={6} my={6}>
      <Container>
        <Grid container item xs={11} spacing={3} alignItems="center" sx={{ mx: "auto" }}>
          <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
            <RotatingCard>
              <RotatingCardFront
                image={bgFront}
                icon={<MdTouchApp />}
                title={
                  <>
                    Upskill
                    <br />
                    with TeksTedia
                  </>
                }
                description="Browse through and learn skills that will make you tread the Job market"
              />
              <RotatingCardBack
                image={bgBack}
                title="Learn and Grow"
                description="Get the most up to date content and grow to achieve your dreams in Tech. Check it out"
                action={{
                  type: "internal",
                  route: ".",
                  label: "Download Brochure",
                }}
              />
            </RotatingCard>
          </Grid>
          <Grid item xs={12} lg={7} sx={{ ml: "auto" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon={<MdContentCopy />}
                  title="Full Articles"
                  description="TeksTedia's Study platform teaches clean code best practices for maintainable, scalable software through real-world coding examples."
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon={<MdFlipToFront />}
                  title="Upated Content"
                  description="TeksTedia's Study platform teaches clean code best practices for maintainable, scalable software through real-world coding examples."
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mt: { xs: 0, md: 6 } }}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon={<IoMdCash />}
                  title="Make Money"
                  description="TeksTedia's Study platform teaches clean code best practices for maintainable, scalable software through real-world coding examples."
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon={<MdOutlineDevices />}
                  title="Fully Great"
                  description="TeksTedia's Study platform teaches clean code best practices for maintainable, scalable software through real-world coding examples."
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
