/* eslint-disable no-restricted-syntax */
import { useParams, Outlet } from "react-router-dom";

import { useDispatch } from "react-redux"; // react redux stuff
import store from "app/store";

import Container from "@mui/material/Container";

import DefaultSidebar from "examples/Sidebars/DefaultSidebar";

import { selectedCourseActions } from "app/selectedCourse-slice";
import "./styles.css";

const Course = () => {
  const { name } = useParams();
  const dispatch = useDispatch();
  const { data } = store.getState().courseData;

  const hyphenRemovedName = name.toLowerCase().replace(/-/g, " ");

  let foundCourse = null;
  for (const trainingProgram of data) {
    for (const course of trainingProgram.courses) {
      if (course.name.toLowerCase() === hyphenRemovedName) {
        foundCourse = course;
        dispatch(selectedCourseActions.setCourse(foundCourse));
        break;
      }
    }
  }
  return (
    <div className="course-page">
      <DefaultSidebar foundCourseData={foundCourse} transparent={false} />

      <Container
        padding="0 !important"
        width={{ xs: "100%", lg: "72% !important" }}
        marginLeft={{ xs: "0", lg: "calc(29% - 48px) !important" }}
        sx={{
          height: "100vh",
        }}
      >
        <Outlet foundCourseData={foundCourse} />
      </Container>
    </div>
  );
};

export default Course;
