/*
=========================================================
* LiahStudy - v2.0.0
=========================================================

* Product Page: https://www.tekstedia.com/product/material-kit-react
* Copyright 2021 TeksTedia (https://www.tekstedia.com)

Coded by www.tekstedia.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// react redux stuff
import store from "app/store";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// React-icons
import { MdArrowForward } from "react-icons/md";
// LiahStudy components
import MKBox from "components/MKBox";
import MKBadge from "components/MKBadge";
import MKTypography from "components/MKTypography";

// Presentation page components
import ExampleCard from "pages/Presentation/components/ExampleCard";
// import { sameStruct } from "app/functions";

// Data

function DesignBlocks() {
  const { data } = store.getState().courseData;
  console.log(data);
  // const sameS = sameStruct(courses);
  const buttonStyles = {
    width: "max-content",
    display: "flex",
    alignItems: "center",

    "& .material-icons-round": {
      fontSize: "1.125rem",
      transform: `translateX(3px)`,
      transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
    },

    "&:hover .material-icons-round, &:focus .material-icons-round": {
      transform: `translateX(6px)`,
    },
  };
  const renderData = data.map(({ trainingPrograms, description, courses }) => (
    <Grid container spacing={3} sx={{ mb: 10 }} key={trainingPrograms}>
      <Grid item xs={12} lg={3}>
        <MKBox position="sticky" top="100px" pb={{ xs: 2, lg: 6 }}>
          <MKTypography variant="h3" fontWeight="bold" mb={1}>
            {trainingPrograms}
          </MKTypography>
          <MKTypography variant="body2" fontWeight="regular" color="secondary" mb={1} pr={2}>
            {description}
          </MKTypography>
          <MKTypography
            component={Link}
            to={`/${trainingPrograms.toLowerCase().replace(/\s/g, "-")}`}
            variant="body2"
            fontWeight="regular"
            color="primary"
            sx={buttonStyles}
          >
            Get Started
            <MdArrowForward />
          </MKTypography>
        </MKBox>
      </Grid>
      <Grid item xs={12} lg={9}>
        <Grid container spacing={3}>
          {courses.map(({ image, name, count }) => (
            <Grid item xs={12} md={4} sx={{ mb: 2 }} key={name}>
              <Link to={`/course/${name.toLowerCase().replace(/\s/g, "-")}`}>
                <ExampleCard image={image} name={name} count={count} />
              </Link>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  ));

  return (
    <MKBox component="section" my={6} py={6}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "center", my: 6, mx: "auto", px: 0.75 }}
        >
          <MKBadge
            variant="contained"
            color="info"
            badgeContent="Tons of skills"
            container
            sx={{ mb: 2 }}
          />
          <MKTypography variant="h2" fontWeight="bold">
            Growing Set of Training Programs
          </MKTypography>
          <MKTypography variant="body1" color="text">
            Boost your Tech skills with our expanding range of Training Programs
          </MKTypography>
        </Grid>
      </Container>
      <Container sx={{ mt: 6 }}>{renderData}</Container>
    </MKBox>
  );
}

export default DesignBlocks;
