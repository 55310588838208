import { useState } from "react";
// import IconButton from "@mui/material/IconButton";
// import SearchIcon from "@mui/icons-material/Search";
// import TextField from "@mui/material/TextField";
import "./styles.css";
// prop-types is a library for typechecking of props.
// import PropTypes from "prop-types";

// LiahStudy components
import MKInput from "components/MKInput";

// const List = (item) => (
//   <MKBox
//     textAlign="left"
//     width="23.3vw"
//     height="7vh"
//     fontSize="18px"
//     mx={0}
//     padding="1.5vh 2vh 3vh 2vh"
//     sx={{
//       "&:hover": {
//         transform: "rotateX(7deg)",
//         cursor: "pointer",
//         background: "gainsboro",
//       },
//     }}
//   >
//     <p>{item}</p>
//   </MKBox>
// );

function DefaultSearchbar() {
  const [searchTerm, setSearchTerm] = useState("");
  console.log(searchTerm);
  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };
  return (
    // <MKBox
    //   sx={{
    //     "&:hover": {
    //       transform: "rotateX(7deg)",
    //       border: "1px solid grey",
    //     },
    //   }}
    //   width="20vw"
    //   padding="5px"
    //   borderRadius="xl"
    // >
    //   <MKInput
    //     id="search"
    //     type="search"
    //     label="Search"
    //     value={searchTerm}
    //     onChange={handleChange}
    //     fullWidth
    //   />
    // </MKBox>
    <div className="searchDiv">
      <MKInput
        id="search-bar"
        className="text"
        onInput={() => handleChange}
        label="Type and hit enter"
        variant="filled"
        placeholder="Search ..."
        size="small"
      />
    </div>
  );
}

export default DefaultSearchbar;
