import { createSlice } from "@reduxjs/toolkit";

const courseSyllabiSlice = createSlice({
  name: "courseSyllabi",
  initialState: {
    syllabiContent: null,
  },
  reducers: {
    updateContent(state, action) {
      return {
        ...state,
        syllabiContent: action.payload,
      };
    },
  },
});

export const courseSyllabiActions = courseSyllabiSlice.actions;
export default courseSyllabiSlice;
