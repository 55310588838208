export const ROUTE = {
  TRAINING_PROGRAM: "/training-program/:program/*",
  SEARCH: "/search/:searchTerm",
};

export const TRAINING_PROGRAM_ROUTE = {
  COURSES_LIST: "/courses-list",
  COURSE: "/course/:name/*",
  LESSON: "/course/:name/:lessonName",
};

export function createPath(args) {
  // Save some CPU power for routes without params
  const events = { params: false };
  const key = "params";
  if (args.hasOwnProperty.call(events, key)) return args.path;

  //  Create a path by replacing params in route definition
  return Object.entries(args.params).reduce((previousValue, [param, value]) =>
    previousValue.replace(`:${param}`, `${value}`)
  );
}
