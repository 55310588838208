import MKBox from "components/MKBox";
// LiahStudy examples
// import DefaultSidebar from "examples/Sidebars/DefaultSidebar";
// import SimpleFooter from "examples/Footers/SimpleFooter";

// Routes
// import footerRoutes from "footer.routes";

const TrainingProgram = () => (
  <MKBox backgroundColor="white">Hello . Training Program. . World</MKBox>
);

export default TrainingProgram;
