import { useParams, Link } from "react-router-dom";

import { useState } from "react";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import YouTube from "react-youtube";
import MKBox from "components/MKBox";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";

// react redux stuff
// import { useSelector } from "react-redux";// react redux stuff
import store from "app/store";
import Liah from "assets/images/Liah.svg";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const Lesson = () => {
  const { selectedCourse } = store.getState().selectedCourse;
  const { urlLessonName } = useParams();
  const { urlModuleName } = useParams();
  const module = selectedCourse.modules.find((moduleItem) => moduleItem.name === urlModuleName);
  console.log(module);
  console.log(selectedCourse);
  const lesson = module.lessons.find(
    (lessonItem) => lessonItem.name.trim().toLowerCase() === urlLessonName.toLowerCase()
  );
  const routifiedCourseName = selectedCourse.name.toLowerCase().replace(/\s/g, "-");

  const lessonIndex = module.lessons.findIndex(
    (lessonItem) => lessonItem.name.trim().toLowerCase() === urlLessonName.trim().toLowerCase()
  );
  const previousLesson = lessonIndex > 0 ? module.lessons[lessonIndex - 1] : null;
  const nextLesson =
    lessonIndex < module.lessons.length - 1
      ? module.lessons[lessonIndex + 1]
      : module.lessons.length - 1;
  // const [player, setPlayer] = useState(null);
  const firstYoutubeID = lesson.content.videos[0].split("v=")[1];
  // const onReady = (e) => {
  //   setPlayer(e.target);
  // };
  if (!lesson) {
    return <div>Lesson not found</div>;
  }
  // const onPlayHandler = () => {
  //   player.playVideo();
  // };
  // const onPauseHandler = () => {
  //   player.pauseVideo();
  // };

  // Feedback Modal
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <MKBox
      width={{ xs: "100%", lg: "100%" }}
      marginLeft={{ xs: "inherit", lg: "11rem" }}
      pt={{ xs: ".1rem", lg: "2rem" }}
    >
      <aside className="cover" />
      <div className="lesson-nav">
        <MKButton
          component={Link}
          to={`/course/${routifiedCourseName}`}
          color="danger"
          startIcon={<KeyboardBackspaceIcon />}
        >
          Back
        </MKButton>
        <div className="logo">
          <img height="100%" src={Liah} alt="Logo pic" />
          technologies
        </div>
      </div>
      <MKBox
        marginLeft={{ xs: "0", lg: "7rem" }}
        fontSize="17px"
        marginTop={{ xs: "2.9rem", lg: "1rem" }}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <MKBox display="flex" alignItems="center" justifyContent="space-between">
          <div className="about-learning">
            {/* <SquareFootSharpIcon /> */}
            <div>
              <span>My Learning</span>
              <ArrowRightIcon fontSize="large" />
            </div>
            <div>
              <span
                sx={{
                  textDecoration: "underline",
                  "&:hover": {
                    transform: "rotateX(7deg)",
                    cursor: "pointer",
                  },
                }}
              >
                {selectedCourse.name}
              </span>
              <ArrowRightIcon fontSize="large" />
            </div>
            <div>
              <span variant={{ xs: "body3", lg: "body2" }}>{lesson.name}</span>
            </div>
          </div>
        </MKBox>
      </MKBox>
      <MKBox marginLeft={{ xs: ".1rem", lg: "7rem" }} marginTop="4rem">
        <div className="player">
          <YouTube
            videoId={firstYoutubeID}
            // onReady={onReady}
            className="player-inner"
            opts={{
              height: "100%",
              width: "100%",
            }}
          />
        </div>
        {/* <MKButton variant="contained" onClick={onPlayHandler} className="btn">
          Play
        </MKButton>
        <MKButton onClick={onPauseHandler} className="btn">
          Pause
        </MKButton> */}

        <div className="notes">
          <MKTypography variant="body2">{lesson.content.notes}</MKTypography>
        </div>
        <div className="quiz">
          <FormControl>
            <div className="quiz-header">
              <MKTypography>Quiz Question</MKTypography>
              <FormLabel id="group-label">
                <MKTypography variant="body2">{lesson.content.quizzes[0].question}</MKTypography>
              </FormLabel>
            </div>
            <div className="quiz-answers">
              <RadioGroup sx={{ margin: "0" }} aria-labelledby="group-label" name="readio-btns-grp">
                {lesson.content.quizzes[0].answers.map((ans) => (
                  <FormControlLabel value={ans} control={<Radio />} label={ans} />
                ))}
              </RadioGroup>
            </div>
          </FormControl>
        </div>
        <br />
        <MKButton color="info" variant="contained">
          Submit
        </MKButton>
        <br />
        <br />
        <br />
        <hr />
        <div className="actions">
          <Stack direction="row" spacing={2}>
            {/* Previous Button */}
            {lessonIndex > 0 && (
              <MKButton
                component={Link}
                to={`/course/${routifiedCourseName}/module/${module.name}/lesson/${previousLesson.name}`}
                color="info"
                variant="outlined"
                startIcon={<KeyboardBackspaceIcon />}
              >
                Previous
              </MKButton>
            )}
            {/* Next Button */}
            {lessonIndex < module.lessons.length - 1 && (
              <MKButton
                component={Link}
                to={`/course/${routifiedCourseName}/module/${module.name}/lesson/${nextLesson.name}`}
                color="info"
                variant="contained"
                endIcon={<ArrowRightAltIcon />}
              >
                Next
              </MKButton>
            )}
          </Stack>
          <MKTypography
            component={MKButton}
            onClick={handleOpen}
            color="info"
            fontWeight={600}
            fontSize={{ xs: "10px", lg: "16px" }}
          >
            Give Page Feedback
          </MKTypography>
        </div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <MKBox sx={style}>
            <MKTypography id="modal-modal-title" variant="h6" component="h2">
              Send feedback
            </MKTypography>
            <MKTypography id="modal-modal-description" sx={{ mt: 2 }}>
              What is your feedback?
            </MKTypography>
            <TextField id="filled-basic" label="what can you say?" variant="filled" />
            <MKBox marginTop="1rem">
              <MKButton disabled color="info" variant="contained">
                Send
              </MKButton>
            </MKBox>
          </MKBox>
        </Modal>
      </MKBox>
    </MKBox>
  );
};

export default Lesson;
