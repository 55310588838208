import { createSlice } from "@reduxjs/toolkit";

const imagesPrefix = "src/assets/images";

const initialState = [
  {
    image: `${imagesPrefix}/bg2.jpg`,
    lessons: [
      {
        name: "Overview",
        content: {
          quizes: [],
          videos: [],
          notes: `[Overview] The "Fundamentals of HTML, CSS, and JS" course is designed to provide web developers, from novice to expert level, with a solid overview of HTML. Whether you're just starting out or have been building websites for years, this course offers valuable knowledge and fills in any gaps you may have in your understanding of HTML.

Throughout the course, you'll embark on a journey to build the structure for MachineLearningWorkshop.com. It's important to note that no machines were harmed during the creation of this series.

Please keep in mind that this course is not a complete reference. Each section introduces a specific topic with concise explanations and examples, encouraging further exploration. You'll find links to topic references such as MDN and WHATWG specifications, as well as other web.dev articles, providing additional resources for in-depth learning. While this course does not focus exclusively on accessibility, each section includes accessibility best practices and highlights specific issues, with links to delve deeper into the topic. Additionally, each section concludes with a short assessment to help solidify your understanding.

Here's what you'll learn:

Overview of HTML: This section offers a brief introduction to the key concepts in HTML.
Document structure: Learn how to structure your HTML documents with a strong foundation.
Metadata: Understand how to utilize meta tags to provide information about your documents.
Semantic HTML: Discover the importance of using the correct HTML elements to describe your document content accurately.
Headings and sections: Learn the proper usage of sectioning elements to give meaning to your content.
Attributes: Explore different global attributes and attributes specific to particular HTML elements.
Text basics: Master the art of formatting text using HTML.
Links: Gain comprehensive knowledge about creating and working with links.
Lists: Understand various ways to group and organize your content using lists.
Navigation: Learn how to implement navigation, a crucial element for any website or application, using HTML.
Tables: Acquire the skills to effectively mark up tabular data using HTML tables.
Forms: Get an overview of forms in HTML and their components.
Images: Discover how to work with images in HTML.
Audio and Video: Explore the incorporation and manipulation of audio and video content using HTML.
Template, slot, and shadow: Understand the concepts of template, slot, and shadow in HTML.
HTML APIs: Learn how HTML information can be exposed and manipulated using JavaScript.
Focus: Discover techniques for managing the focus order in your HTML documents.
Other inline text elements: Get acquainted with a range of elements used for marking up text.
Details and summary: Understand the functionality of the details and summary elements and their appropriate usage.
Dialog: Explore the dialog element, a useful tool for representing different types of dialogues in HTML.
Conclusion and next steps: Wrap up the course with additional resources for further learning.

So, if you're ready to learn HTML and enhance your web development skills, let's get started on this exciting journey!
              `,
        },
      },
      {
        name: "New Eufi",
        content: {
          quizes: [],
          videos: [],
          notes:
            "null is used for representing the intentional absence of an object value and is a primitive value. Unlike undefined, it is not a property of the global object.",
        },
      },
      {
        name: "CIOc ds",
        content: {
          quizes: [],
          videos: [],
          notes:
            "null is used for representing the intentional absence of an object value and is a primitive value. Unlike undefined, it is not a property of the global object.",
        },
      },
      {
        name: "Bgeini ",
        content: {
          quizes: [],
          videos: [],
          notes:
            "null is used for representing the intentional absence of an object value and is a primitive value. Unlike undefined, it is not a property of the global object.",
        },
      },
      {
        name: "Immediately Evoked",
        content: {
          quizes: [],
          videos: [],
          notes:
            "null is used for representing the intentional absence of an object value and is a primitive value. Unlike undefined, it is not a property of the global object.",
        },
      },
      {
        name: "Xexrse Zul",
        content: {
          quizes: [],
          videos: [],
          notes:
            "null is used for representing the intentional absence of an object value and is a primitive value. Unlike undefined, it is not a property of the global object.",
        },
      },
      {
        name: "JIocdsc ",
        content: {
          quizes: [],
          videos: [],
          notes:
            "null is used for representing the intentional absence of an object value and is a primitive value. Unlike undefined, it is not a property of the global object.",
        },
      },
      {
        name: "cdoinsoi sd",
        content: {
          quizes: [],
          videos: [],
          notes:
            "null is used for representing the intentional absence of an object value and is a primitive value. Unlike undefined, it is not a property of the global object.",
        },
      },
      {
        name: "Marvel of Z-index",
        content: {
          quizes: [],
          videos: [],
          notes:
            "null is used for representing the intentional absence of an object value and is a primitive value. Unlike undefined, it is not a property of the global object.",
        },
      },
      {
        name: "uiewe d",
        content: {
          quizes: [],
          videos: [],
          notes:
            "null is used for representing the intentional absence of an object value and is a primitive value. Unlike undefined, it is not a property of the global object.",
        },
      },
    ],

    name: "Fundamentals of HTML CSS and JS",
    count: 10,
  },
  {
    image: `${imagesPrefix}/bg3.jpg`,
    lessons: [
      {
        name: "Learn HTML",
        content:
          "This should be the first item, displaying the table of contents in a welcoming and interesting manner This should be the first item, displaying the table of contents in a welcoming and interesting manner",
      },
      {
        name: "Learn CSS",
        content: {
          quizes: [],
          videos: [],
          notes:
            "null is used for representing the intentional absence of an object value and is a primitive value. Unlike undefined, it is not a property of the global object.",
        },
      },
      {
        name: "Learn CSS",
        content: {
          quizes: [],
          videos: [],
          notes:
            "null is used for representing the intentional absence of an object value and is a primitive value. Unlike undefined, it is not a property of the global object.",
        },
      },
      {
        name: "Learn CSS",
        content: {
          quizes: [],
          videos: [],
          notes:
            "null is used for representing the intentional absence of an object value and is a primitive value. Unlike undefined, it is not a property of the global object.",
        },
      },
      {
        name: "Learn CSS",
        content: {
          quizes: [],
          videos: [],
          notes:
            "null is used for representing the intentional absence of an object value and is a primitive value. Unlike undefined, it is not a property of the global object.",
        },
      },
      {
        name: "Learn CSS",
        content: {
          quizes: [],
          videos: [],
          notes:
            "null is used for representing the intentional absence of an object value and is a primitive value. Unlike undefined, it is not a property of the global object.",
        },
      },
      {
        name: "Learn CSS",
        content: {
          quizes: [],
          videos: [],
          notes:
            "null is used for representing the intentional absence of an object value and is a primitive value. Unlike undefined, it is not a property of the global object.",
        },
      },
      {
        name: "Learn CSS",
        content: {
          quizes: [],
          videos: [],
          notes:
            "null is used for representing the intentional absence of an object value and is a primitive value. Unlike undefined, it is not a property of the global object.",
        },
      },
    ],

    name: "UX Design Principles",
    count: 14,
  },
  {
    image: `${imagesPrefix}/bg-about-us.jpg`,
    lessons: [
      {
        name: "Typography Basics",
        content:
          "Typography is the art of arranging letters and text to make the textual content legible, easily digestible, and visually appealing to the reader.In whichever product you use (especially digital ones) a designer (or a whole design team) carefully and strategically agreed upon a font style, size, and structure to deepen and convey the text’s message",
      },
      {
        name: "Fonts and Variants",
        content: {
          quizes: [],
          videos: [],
          notes:
            "null is used for representing the intentional absence of an object value and is a primitive value. Unlike undefined, it is not a property of the global object.",
        },
      },
      { name: "Figma", content: "Figma is a platform used by designers" },
      {
        name: "White Space",
        content:
          "White space is the empty space that surrounds the text, images or other graphics in interfaces",
      },
      {
        name: "Kerning",
        content:
          "Kerning is another method of arranging typography in UX/UI design. It has similarities with tracking, but they are not the same",
      },
      {
        name: "Tracking",
        content:
          "Tracking is a process of adjusting the space for a group of type characters which form a word and text block.",
      },
      {
        name: "Consistency",
        content:
          "A good designer always ensures the consistency of typography in their design. If there is no consistency with different typefaces and fonts, your product will look messy, confusing, unattractive, and hard to digest for future users. ",
      },
    ],

    name: "Responsive Design Techniques",
    count: 8,
    pro: true,
  },
  {
    image: `${imagesPrefix}/bg2.jpg`,
    lessons: [
      {
        name: "Overview",
        content: {
          quizes: [],
          videos: [],
          notes:
            "null is used for representing the intentional absence of an object value and is a primitive value. Unlike undefined, it is not a property of the global object.",
        },
      },
      {
        name: "Overview",
        content: {
          quizes: [],
          videos: [],
          notes:
            "null is used for representing the intentional absence of an object value and is a primitive value. Unlike undefined, it is not a property of the global object.",
        },
      },
      {
        name: "Overview",
        content: {
          quizes: [],
          videos: [],
          notes:
            "null is used for representing the intentional absence of an object value and is a primitive value. Unlike undefined, it is not a property of the global object.",
        },
      },
      {
        name: "Overview",
        content: {
          quizes: [],
          videos: [],
          notes:
            "null is used for representing the intentional absence of an object value and is a primitive value. Unlike undefined, it is not a property of the global object.",
        },
      },
      {
        name: "Overview",
        content: {
          quizes: [],
          videos: [],
          notes:
            "null is used for representing the intentional absence of an object value and is a primitive value. Unlike undefined, it is not a property of the global object.",
        },
      },
      {
        name: "Overview",
        content: {
          quizes: [],
          videos: [],
          notes:
            "null is used for representing the intentional absence of an object value and is a primitive value. Unlike undefined, it is not a property of the global object.",
        },
      },
      {
        name: "Marvel of Z-index",
        content: {
          quizes: [],
          videos: [],
          notes:
            "null is used for representing the intentional absence of an object value and is a primitive value. Unlike undefined, it is not a property of the global object.",
        },
      },
    ],

    name: "Web Design Project Management and workflow",
    count: 1,
    pro: true,
  },
  {
    image: `${imagesPrefix}/bg3.jpg`,
    lessons: [
      {
        name: "Overview",
        content: {
          quizes: [],
          videos: [],
          notes:
            "null is used for representing the intentional absence of an object value and is a primitive value. Unlike undefined, it is not a property of the global object.",
        },
      },
      {
        name: "Marvel of Z-index",
        content: {
          quizes: [],
          videos: [],
          notes:
            "null is used for representing the intentional absence of an object value and is a primitive value. Unlike undefined, it is not a property of the global object.",
        },
      },
      {
        name: "Marvel of Z-index",
        content: {
          quizes: [],
          videos: [],
          notes:
            "null is used for representing the intentional absence of an object value and is a primitive value. Unlike undefined, it is not a property of the global object.",
        },
      },
      {
        name: "Marvel of Z-index",
        content: {
          quizes: [],
          videos: [],
          notes:
            "null is used for representing the intentional absence of an object value and is a primitive value. Unlike undefined, it is not a property of the global object.",
        },
      },
      {
        name: "Marvel of Z-index",
        content: {
          quizes: [],
          videos: [],
          notes:
            "null is used for representing the intentional absence of an object value and is a primitive value. Unlike undefined, it is not a property of the global object.",
        },
      },
    ],

    name: "CMS and Web Hosting Basics",
    count: 11,
    pro: true,
  },
  {
    image: `${imagesPrefix}/bg-about-us.jpg`,
    lessons: [
      {
        name: "Overview",
        content: {
          quizes: [],
          videos: [],
          notes:
            "null is used for representing the intentional absence of an object value and is a primitive value. Unlike undefined, it is not a property of the global object.",
        },
      },
      {
        name: "Overview",
        content: {
          quizes: [],
          videos: [],
          notes:
            "null is used for representing the intentional absence of an object value and is a primitive value. Unlike undefined, it is not a property of the global object.",
        },
      },
      {
        name: "Marvel of Z-index",
        content: {
          quizes: [],
          videos: [],
          notes:
            "null is used for representing the intentional absence of an object value and is a primitive value. Unlike undefined, it is not a property of the global object.",
        },
      },
      {
        name: "Marvel of Z-index",
        content: {
          quizes: [],
          videos: [],
          notes:
            "null is used for representing the intentional absence of an object value and is a primitive value. Unlike undefined, it is not a property of the global object.",
        },
      },
      {
        name: "Marvel of Z-index",
        content: {
          quizes: [],
          videos: [],
          notes:
            "null is used for representing the intentional absence of an object value and is a primitive value. Unlike undefined, it is not a property of the global object.",
        },
      },
      {
        name: "Marvel of Z-index",
        content: {
          quizes: [],
          videos: [],
          notes:
            "null is used for representing the intentional absence of an object value and is a primitive value. Unlike undefined, it is not a property of the global object.",
        },
      },
      {
        name: "Marvel of Z-index",
        content: {
          quizes: [],
          videos: [],
          notes:
            "null is used for representing the intentional absence of an object value and is a primitive value. Unlike undefined, it is not a property of the global object.",
        },
      },
    ],

    name: "Accessibility and inclusive Design for the Web",
    count: 11,
    pro: true,
  },
  {
    image: `${imagesPrefix}/bg2.jpg`,
    lessons: [
      {
        name: "Overview",
        content: {
          quizes: [],
          videos: [],
          notes:
            "null is used for representing the intentional absence of an object value and is a primitive value. Unlike undefined, it is not a property of the global object.",
        },
      },
      {
        name: "Marvel of Z-index",
        content: {
          quizes: [],
          videos: [],
          notes:
            "null is used for representing the intentional absence of an object value and is a primitive value. Unlike undefined, it is not a property of the global object.",
        },
      },
    ],

    name: "Advanced webdesign Concepts and Emerging Tech",
    count: 6,
    pro: true,
  },
  {
    image: `${imagesPrefix}/bg2.jpg`,
    lessons: [
      {
        name: "Overview",
        content: {
          quizes: [],
          videos: [],
          notes:
            "null is used for representing the intentional absence of an object value and is a primitive value. Unlike undefined, it is not a property of the global object.",
        },
      },
      {
        name: "Marvel of Z-index",
        content: {
          quizes: [],
          videos: [],
          notes:
            "null is used for representing the intentional absence of an object value and is a primitive value. Unlike undefined, it is not a property of the global object.",
        },
      },
    ],

    name: "Stats",
    count: 3,
    pro: true,
  },
  {
    image: `${imagesPrefix}/bg3.jpg`,
    lessons: [
      {
        name: "Overview",
        content: {
          quizes: [],
          videos: [],
          notes:
            "null is used for representing the intentional absence of an object value and is a primitive value. Unlike undefined, it is not a property of the global object.",
        },
      },
      {
        name: "Marvel of Z-index",
        content: {
          quizes: [],
          videos: [],
          notes:
            "null is used for representing the intentional absence of an object value and is a primitive value. Unlike undefined, it is not a property of the global object.",
        },
      },
    ],

    name: "Applications",
    count: 6,
    pro: true,
  },
];
const selectedCourseSlice = createSlice({
  name: "selectedCourse",
  initialState: {
    selectedCourse: initialState,
    isLoading: false,
    error: null,
  },
  reducers: {
    setCourse(state, action) {
      const { payload: selectedCourse } = action;
      return {
        ...state,
        selectedCourse,
      };
    },
  },
});

export const selectedCourseActions = selectedCourseSlice.actions;

export default selectedCourseSlice;
