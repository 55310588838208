/**
=========================================================
* LiahStudy - v2.0.0
=========================================================

* Product Page: https://www.tekstedia.com/product/material-kit-react
* Copyright 2021 TeksTedia (https://www.tekstedia.com)

Coded by www.tekstedia.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// LiahStudy base styles
import typography from "assets/theme/base/typography";
import colors from "assets/theme/base/colors";

// LiahStudy helper functions
// import pxToRem from "assets/theme/functions/pxToRem";

const { size } = typography;
const { text } = colors;

export default {
  styleOverrides: {
    root: {
      fontSize: size.md,
      color: text.main,
    },
  },
};
