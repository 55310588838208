import { createSlice } from "@reduxjs/toolkit";
import trainingProgramsData from "./courseData";

const courseDataSlice = createSlice({
  name: "courseData",
  initialState: {
    data: trainingProgramsData,
    isLoading: false,
    error: null,
  },
  reducers: {
    fetchCoursesStart(state) {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    },
    fetchCoursesSuccess(state, action) {
      return {
        ...state,
        data: action.payload,
        isLoading: false,
        error: null,
      };
    },
    fetchCoursesFail(state, action) {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },
  },
});
export const courseDataActions = courseDataSlice.actions;

export default courseDataSlice;
