/**
=========================================================
* LiahStudy - v2.0.0
=========================================================

* Product Page: https://www.tekstedia.com/portfolio/LiahStudy
* Copyright 2023 TeksTedia (https://www.tekstedia.com)

Coded by www.tekstedia.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect } from "react";

// import { useSelector } from "react-redux";

// react-router components
import { Routes, Route, useLocation, Navigate } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// LiahStudy theme
import theme from "assets/theme";
import Presentation from "layouts/pages/presentation";
import TrainingProgram from "pages/Programs/TrainingProgram";
import SearchResults from "pages/SearchResults/SearchResults";
// import Course from "layouts/pages/Programs/course";
import Lesson from "layouts/pages/Programs/Lesson";
import SingularCourse from "pages/Course/SingularCourse";
import Course from "layouts/pages/Programs/course";

// LiahStudy routes
// import routes from "routes/routes";

export default function App() {
  const { pathname } = useLocation();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        {/* {getRoutes(routes)} */}
        <Route element={<TrainingProgram />} path="/training-program/:program/*" exact />
        <Route element={<SearchResults />} path="/search/:searchTerm" exact />
        {/** Displays the modules */}
        <Route element={<SingularCourse />} path="/course/:name/*" exact>
          <Route element={<Course />} path="module/:urlModuleName/*" exact>
            <Route element={<Lesson />} path="lesson/:urlLessonName" exact />
            <Route element={<Navigate to="lesson/Overview" />} path="*" exact />
          </Route>

          {/* <Route path="/course/:name/*" element={<Navigate to="lesson/Overview" />} /> */}
        </Route>

        <Route path="*" element={<Presentation />} />
        {/* <Route path="*" element={<Navigate to="/presentation" />} /> */}
      </Routes>
    </ThemeProvider>
  );
}
